//Type
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;

$font-weight-strong: $font-weight-bold !default;

$font-family-serif: serif !default;
$font-family-sans-serif: "Helvetica Neue", Arial, sans-serif !default;
$font-family-monospace: "Courier New", monospace !default;
$font-family-display: $font-family-serif !default;

$font-family-base: $font-family-sans-serif !default;
$font-weight-base: $font-weight-regular !default;
$paragraph-margin-bottom: $spacer !default;

$font-styles: () !default;

// $responsive-font-sizes: () !default;
$responsive-font-sizes: (
  "1": ( xs: ( font-size: inherit, line-height: inherit ) ),
  "2": ( xs: ( font-size: inherit, line-height: inherit ) ),
  "3": ( xs: ( font-size: inherit, line-height: inherit ) ),
  "4": ( xs: ( font-size: inherit, line-height: inherit ) ),
  "5": ( xs: ( font-size: inherit, line-height: inherit ) ),
  "6": ( xs: ( font-size: inherit, line-height: inherit ) ),
  "blockquote": ( xs: ( font-size: rem-calc(30px), line-height: 1 ) ),
  "body": ( xs: ( font-size: rem-calc(16px), line-height: unitless-calc(20px, rem-calc(16px)) ) ),
  "small": ( xs: ( font-size: rem-calc(12px), line-height: unitless-calc(16px, rem-calc(12px)) ) )
) !default;

// $body-font-size: 1rem !default;
// $body-line-height: unitless-calc(26px, $body-font-size) !default;
$body-font-size: map-get($responsive-font-sizes, "body") !default;

//Headings
$headings-font-weight: $font-weight-bold !default;
$headings-font-family: inherit !default;
$headings-color: inherit !default;

$heading-1-weight: $headings-font-weight !default;
$heading-1-font-size: map-get($responsive-font-sizes, "1") !default;

$heading-2-weight: $headings-font-weight !default;
$heading-2-font-size: map-get($responsive-font-sizes, "2") !default;

$heading-3-weight: $headings-font-weight !default;
$heading-3-font-size: map-get($responsive-font-sizes, "3") !default;

$heading-4-weight: $headings-font-weight !default;
$heading-4-font-size: map-get($responsive-font-sizes, "4") !default;

$heading-5-weight: $headings-font-weight !default;
$heading-5-font-size: map-get($responsive-font-sizes, "5") !default;

$heading-6-weight: $headings-font-weight !default;
$heading-6-font-size: map-get($responsive-font-sizes, "6") !default;

// Formatting
$heading-margin-bottom: rem-calc(10px) !default;
$heading-1-margin-bottom: $heading-margin-bottom !default;
$heading-2-margin-bottom: $heading-margin-bottom !default;
$heading-3-margin-bottom: $heading-margin-bottom !default;
$heading-4-margin-bottom: $heading-margin-bottom !default;
$heading-5-margin-bottom: $heading-margin-bottom !default;
$heading-6-margin-bottom: $heading-margin-bottom !default;

// Lead text
$lead-font-family: inherit !default;
$lead-font-size: map-get($responsive-font-sizes, "lead") !default;
$lead-font-weight: $font-weight-regular !default;
$lead-color: inherit !default;

// Blockquote
$blockquote-font-family: inherit !default;
$blockquote-font-size: map-get($responsive-font-sizes, "blockquote") !default;
$blockquote-font-weight: $headings-font-weight !default;
$blockquote-color: inherit !default;
$blockquote-paragraph-margin-bottom: rem-calc(20px) !default;

// Small
$small-font-size: map-get($responsive-font-sizes, "small") !default;
// $small-line-height: unitless-calc(16px, $small-font-size)!default;

//Uppercase
$uppercase-letter-spacing: inherit !default;
$uppercase-font-weight: inherit !default;