body {
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  @include font-size-map($body-font-size);
}

h1, h2, h3, h4, h5, h6{
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

// h1{
//   font-weight: $heading-1-weight;
// }

// h2{
//   font-weight: $heading-2-weight;
// }

// h3{
//   font-weight: $heading-3-weight;
// }

// h4{
//   font-weight: $heading-4-weight;
// }

// h5{
//   font-weight: $heading-5-weight;
// }

// h6{
//   font-weight: $heading-6-weight;
// }

h1, h2, h3, h4, h5, h6,
.heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
  font-family: $headings-font-family;
  color: $headings-color;
}

.heading-1 { 
  @include heading-1();
}
.heading-2 {
  @include heading-2();
}
.heading-3 {
  @include heading-3();
}
.heading-4 {
  @include heading-4();
}
.heading-5{
  @include heading-5();
}
.heading-6{
  @include heading-6();
}

.formatted{
  &.headings{
    h1{
      @include heading-1();
      margin-bottom: $heading-1-margin-bottom;
    }
    h2{
      @include heading-2();
      margin-bottom: $heading-2-margin-bottom;
    }
    h3{
      @include heading-3();
      margin-bottom: $heading-3-margin-bottom;
    }
    h4{
      @include heading-4();
      margin-bottom: $heading-4-margin-bottom;
    }
    h5{
      @include heading-5();
      margin-bottom: $heading-5-margin-bottom;
    }
    h6{
      @include heading-6();
      margin-bottom: $heading-6-margin-bottom;
    }
  }
  &.paragraphs{
    p{
      margin-bottom: $paragraph-margin-bottom;
    }    
  }
}

blockquote, .blockquote {
  p{
    margin-bottom: $blockquote-paragraph-margin-bottom;
  }
  > *:last-child{
    margin-bottom: 0 !important;
  }
}

small, .text-small{
  @include text-small();
}

b, strong {
  font-weight: $font-weight-strong;
}

pre,
code,
kbd,
samp {
  font-family: $font-family-monospace;
}

// Lead Text
.lead {
  @include lead();
}

// Blockquote
blockquote, .blockquote {
  @include blockquote();
}