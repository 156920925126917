@mixin button-variant(
$button-font-family: null,
$button-font-size: null,
$button-font-weight: null,
$button-color: null,
$button-bg: null,

$button-bordered: null,
$button-border-color: null,
$button-border-width: null,
$button-border-radius: null,

$button-color-hover: null,
$button-bg-hover: null,
$button-border-color-hover: null,
$button-color-active: null,
$button-bg-active: null,
$button-border-color-active: null,
$button-color-disabled: null,
$button-bg-disabled: null,
$button-opacity-disabled: null,
$button-padding: null,
$button-size: null,
){
  display: inline-block;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  transition: color $transition-duration $transition-timing-function, background $transition-duration $transition-timing-function;
  font-family: $button-font-family;
  font-weight: $button-font-weight;
  background: $button-bg;
  color: $button-color;
  @if $button-bordered == true {
    border-width: $button-border-width;
    border-style: solid;
    border-color: $button-border-color;
    transition: border-color $transition-duration $transition-timing-function, color $transition-duration $transition-timing-function, background $transition-duration $transition-timing-function;
    @include hover{
      border-color: $button-border-color-hover;
    }
    @if $button-padding {
      @include button-size($button-padding, true);
    }
  }
  .fill{
    fill: $button-color;
  }
  .stroke{
    stroke: $button-color;
  }
  @include button-padding($button-padding);
  @include button-size($button-size);
  @include font-size-map($button-font-size);
  @include hover{
    color: $button-color-hover;
    background: $button-bg-hover;
    .fill{
      fill: $button-color-hover;
    }
    .stroke{
      stroke: $button-color-hover;
    }
  }
  &:focus{
    color: $button-color-hover;
    background: $button-bg-hover;
    .fill{
      fill: $button-color-hover;
    }
    .stroke{
      stroke: $button-color-hover;
    }
  }
  @include hover-focus-active {
    opacity: 1;
  }
  @include border-radius($button-border-radius);
  &:disabled,
  &[readonly] {
    cursor: default;
    color: $button-color-disabled;
    background-color: $button-bg-disabled;
    opacity: $button-opacity-disabled;
  }
}

// @mixin button-color(
//   $button-color: $button-color,
//   $button-bg: $button-bg,
//   $button-border-color: $button-border-color,
//   $button-color-hover: $button-color-hover,
//   $button-bg-hover: $button-bg-hover,
//   $button-border-color-hover: $button-border-color-hover,
//   $button-color-active: $button-color-active,
//   $button-bg-active: $button-bg-active,
//   $button-border-color-active: $button-border-color-active
// ){
//   color: $button-color;
//   background: $button-bg;
//   .fill{
//     fill: $button-color;
//   }
//   @include hover {
//     color: $button-color-hover;
//     border-color: $button-bg-hover;
//     background: $button-bg-hover;
//     .fill{
//       fill: $button-color-hover;
//     }
//   }
//   &.active{
//     color: $button-color-active;
//     background: $button-bg-active;
//     .fill{
//       fill: $button-color-active;
//     }
//   }
//   &.bordered{
//     border-color: $button-border-color;
//     @include hover{
//       border-color: $button-border-color-hover;
//     }
//   }
//   &.bordered.active{
//     border-color: $button-bg-active;
//   }
// }

// @mixin button-color($button-color: $button-color, $button-bg: $button-bg, $button-border-color: $button-border-color, $button-color-hover: $button-color-hover, $button-bg-hover: $button-bg-hover, $button-border-color-hover: $button-border-color-hover ){
//   .button{
//     @include button-color($button-color, $button-bg, $button-border-color, $button-color-hover, $button-bg-hover, $button-border-color-hover);
//   }
// }

@mixin button-size($size, $bordered: false) {
  @if $size != null {
    @if type-of($size) == "map" and map-has-key($size, "xs"){
      @each $breakpoint, $size in $size {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
          @if $bordered {
            height: map-get($size, "height") - rem-calc($button-border-width)*2;
            width: map-get($size, "width") - rem-calc($button-border-width)*2;
          } @else {
            height: map-get($size, "height");
            width: map-get($size, "width");
          }
        }
      }
    } @else {
      @if $bordered {
        height: map-get($size, "height") - rem-calc($button-border-width)*2;
        width: map-get($size, "width") - rem-calc($button-border-width)*2;
      } @else {
        height: map-get($size, "height");
        width: map-get($size, "width");
      }
    }
  }
}

@mixin button-padding($padding, $bordered: false) {
  @if $padding != null {
    @if type-of($padding) == "map" and map-has-key($padding, "xs"){
      @each $breakpoint, $padding in $padding {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
          @if $bordered {
            padding-top: map-get($padding, "top") - rem-calc($button-border-width);
            padding-right: map-get($padding, "right") - rem-calc($button-border-width);
            padding-bottom: map-get($padding, "bottom") - rem-calc($button-border-width);
            padding-left: map-get($padding, "left") - rem-calc($button-border-width);
          } @else {
            padding-top: map-get($padding, "top");
            padding-right: map-get($padding, "right");
            padding-bottom: map-get($padding, "bottom");
            padding-left: map-get($padding, "left");
          }
        }
      }
    } @else {
      @if $bordered {
        padding-top: map-get($padding, "top") - rem-calc($button-border-width);
        padding-right: map-get($padding, "right") - rem-calc($button-border-width);
        padding-bottom: map-get($padding, "bottom") - rem-calc($button-border-width);
        padding-left: map-get($padding, "left") - rem-calc($button-border-width);
      } @else {
        padding-top: map-get($padding, "top");
        padding-right: map-get($padding, "right");
        padding-bottom: map-get($padding, "bottom");
        padding-left: map-get($padding, "left");
      }
    }
  }
}

// @mixin button() {
//   display: inline-block;
//   font-family: $button-font-family;
//   font-weight: $button-font-weight;
//   @include button-size($button-padding);
//   @include font-size-map($button-font-size);
//   text-align: center;
//   background: $button-bg;
//   cursor: pointer;
//   opacity: 1;
//   color: $button-color;
//   transition: color $transition-duration $transition-timing-function, background $transition-duration $transition-timing-function;
//   .fill{
//     fill: $button-color;
//   }
//   .stroke{
//     stroke: $button-color;
//   }
//   @include hover{
//     color: $button-color-hover;
//     background: $button-bg-hover;
//     .fill{
//       fill: $button-color-hover;
//     }
//     .stroke{
//       stroke: $button-color-hover;
//     }
//   }
//   &:focus{
//     color: $button-color-hover;
//     background: $button-bg-hover;
//     .fill{
//       fill: $button-color-hover;
//     }
//     .stroke{
//       stroke: $button-color-hover;
//     }
//   }
//   @include hover-focus-active {
//     opacity: 1;
//   }
//   @include border-radius($button-border-radius);
//   &.button-lg {
//     @include button-size($button-padding-lg);
//   }

//   &.button-sm {
//     @include button-size($button-padding-sm);
//   }
//   &.bordered{
//     @include button-border();
//   }
//   &.bordered.button-sm {
//     @include font-size-map($button-font-size-sm);
//     @include button-size($button-padding-sm, true);
//   }

//   &.bordered.button-lg {
//     @include button-size($button-padding-lg, true);
//   }
//   &.uppercase{
//     @include text-uppercase();
//     @include text-bold();
//   }
//   &:disabled,
//   &[readonly] {
//     cursor: default;
//     color: $button-color-disabled;
//     background-color: $button-bg-disabled;
//     opacity: $button-opacity-disabled;
//   }
// }