.grid {
  display: grid;
  grid-column-gap: rem-calc(20px);
  grid-row-gap: rem-calc(20px);
}

.grid-cols-10 {
  // grid-template-columns: repeat(15, minmax(0, 1fr));
  grid-template-columns: repeat(10, auto);
}

.grid-cols-15 {
  grid-template-columns: repeat(15, minmax(0, 1fr));
}

.auto-cols-fr{
  grid-auto-columns: minmax(0, 1fr);
}

//Columns
.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-13 {
  grid-column: span 13 / span 13;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-auto {
  grid-column-start: auto;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-auto {
  grid-column-end: auto;
}

//Rows

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-full {
  grid-row: 1 / -1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-auto {
  grid-row-end: auto;
}

@include media-breakpoint-up(lg) {

  .col-lg-auto {
    grid-column: auto;
  }

  .row-lg-span-2 {
    grid-row: span 2 / span 2;
  }

  .col-lg-span-1 {
    grid-column: span 1 / span 1;
  }

  .col-lg-span-2 {
    grid-column: span 2 / span 2;
  }

  .col-lg-span-3 {
    grid-column: span 3 / span 3;
  }

  .col-lg-span-4 {
    grid-column: span 4 / span 4;
  }

  .col-lg-span-5 {
    grid-column: span 5 / span 5;
  }

  .col-lg-span-6 {
    grid-column: span 6 / span 6;
  }

  .col-lg-span-7 {
    grid-column: span 7 / span 7;
  }

  .col-lg-span-8 {
    grid-column: span 8 / span 8;
  }

  .col-lg-span-9 {
    grid-column: span 9 / span 9;
  }

  .col-lg-span-10 {
    grid-column: span 10 / span 10;
  }

  .col-lg-span-11 {
    grid-column: span 11 / span 11;
  }

  .col-lg-span-12 {
    grid-column: span 12 / span 12;
  }

  .col-lg-span-13 {
    grid-column: span 13 / span 13;
  }

  .col-lg-span-15 {
    grid-column: span 15 / span 15;
  }

  .col-lg-span-16 {
    grid-column: span 16 / span 16;
  }

  .col-lg-span-18 {
    grid-column: span 18 / span 18;
  }

  .col-lg-span-20 {
    grid-column: span 20 / span 20;
  }

  .col-lg-span-23 {
    grid-column: span 23 / span 23;
  }

  .col-lg-span-24 {
    grid-column: span 24 / span 24;
  }

  .col-lg-span-25 {
    grid-column: span 25 / span 25;
  }

  .col-lg-span-full {
    grid-column: 1 / -1;
  }

  .col-lg-start-2 {
    grid-column-start: 2;
  }

  .col-lg-start-3 {
    grid-column-start: 3;
  }

  .col-lg-start-6 {
    grid-column-start: 6;
  }

  .col-lg-start-5 {
    grid-column-start: 5;
  }

  .col-lg-start-7 {
    grid-column-start: 7;
  }

  .col-lg-start-8 {
    grid-column-start: 8;
  }

  .col-lg-start-9 {
    grid-column-start: 9;
  }

  .col-lg-start-12 {
    grid-column-start: 12;
  }

  .col-lg-start-13 {
    grid-column-start: 13;
  }

  .col-lg-start-14 {
    grid-column-start: 14;
  }

  .col-lg-start-15 {
    grid-column-start: 15;
  }

  .col-lg-start-16 {
    grid-column-start: 16;
  }

  .col-lg-start-20 {
    grid-column-start: 20;
  }

  .col-lg-start-24 {
    grid-column-start: 24;
  }

  .col-lg-start-25 {
    grid-column-start: 25;
  }

  .grid-lg-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .grid-lg-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .grid-lg-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .grid-lg-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .grid-lg-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .grid-lg-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .grid-lg-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .grid-lg-cols-13 {
    grid-template-columns: repeat(13, minmax(0, 1fr));
  }

  .grid-lg-cols-15 {
    grid-template-columns: repeat(15, minmax(0, 1fr));
  }

  .grid-lg-cols-18 {
    grid-template-columns: repeat(18, minmax(0, 1fr));
  }

  .grid-lg-cols-24 {
    grid-template-columns: repeat(24, minmax(0, 1fr));
  }

  .grid-lg-cols-26 {
    grid-template-columns: repeat(26, minmax(0, 1fr));
  }

  .grid-lg-row-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .grid-lg-row-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
}