$header-offset: rem-calc(79);
$header-offset-lg: rem-calc(91);

$black: #000000;
$white: #FFFFFF;

$grey: #CDCDCD;
$grey-light: #E0E0E0;
$grey-dark: #9A9A9A;

$purple: #4200CE;
$heliotrope: #611DA4;
$pink-light: #EDD2D9;
$blue-grey: #1E2F37;
$pink: #9C228B;
$turquoise: #43A2B9;
$pink-dark: #9A1340;
$green: #899448;
$orange: #AF3003;
$blue: #0E1B6A;
$yellow: #FBB506;

$colors: (
"black": $black,
"white": $white,
"grey": $grey,
"grey-light": $grey-light,
"grey-dark": $grey-dark,
"purple": $purple,
"heliotrope": $heliotrope,
"pink-light": $pink-light,
"blue-grey": $blue-grey,
"pink": $pink,
"turquoise": $turquoise,
"pink-dark": $pink-dark,
"green": $green,
"orange": $orange,
"blue": $blue,
"yellow": $yellow
);

$body-color: $black;

//Spacing
$spacers: (
  0: 0,
  10: rem-calc(10px),
  20: rem-calc(20px),
  30: rem-calc(30px),
  40: rem-calc(40px),
  50: rem-calc(50px),
  55: rem-calc(55px),
  60: rem-calc(60px),
  70: rem-calc(70px),
  75: rem-calc(75px),
  90: rem-calc(90px),
  100: rem-calc(100px),
  110: rem-calc(110px),
  240: rem-calc(240px),
  250: rem-calc(250px),
);

// Grid
$grid-columns: 15;
$grid-gutter-widths: (
  xs: rem-calc(20px)
);

//Type
$font-weight-bold: 724;

$font-family-serif: 'Aime', serif;
$font-family-sans-serif: 'Inter', sans-serif;

$font-styles: (
  "80-u": ( font-size: rem-calc(80px), line-height: 145%, letter-spacing: -0.02em ),
  "60-u": ( font-size: rem-calc(60px), line-height: 145%, letter-spacing: -0.02em ),
  "40-u": ( font-size: rem-calc(40px), line-height: 145%, letter-spacing: -0.02em ),
  "subheading-xl": ( font-size: rem-calc(26px), line-height: 108%, letter-spacing: -0.02em ),
  "body-bold": ( font-size: rem-calc(16px), line-height: 108%, letter-spacing: 0.2em, font-weight: $font-weight-bold, text-transform: uppercase ),
  "body-bold-lg": ( font-size: rem-calc(20px), line-height: 120%, letter-spacing: 0.2em, font-weight: $font-weight-bold, text-transform: uppercase ),
  "body": ( font-size: rem-calc(15px), line-height: 120% ),
  "body-lg": ( font-size: rem-calc(20px), line-height: 120% ),
);

$responsive-font-sizes: (
  "sh": ( xl: map-get($font-styles, "subheading-xl")),
  "body-bold": ( xs: map-get($font-styles, "body-bold"), lg: map-get($font-styles, "body-bold-lg")),
  "body": ( xs: map-get($font-styles, "body"), lg: map-get($font-styles, "body-lg"))
);

//Transitions
$transition-duration: 500ms;
// $transition-duration: 5000ms;
// $transition-duration-slide: 5000ms;

//Utilites
$min-heights: (
  "880": rem-calc(880px)
);

$max-widths: (
  "660": rem-calc(660px)
);