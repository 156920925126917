@use "sass:math";

$base-font-size: 100% !default;

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

@function rem-calc($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: $base-font-size;
  }

  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  @if unit($base) == '%' {
    $base: math.div($base, 100%) * 16px;
  }

  // Using rem as base allows correct scaling
  @if unit($base) == 'rem' {
    $base: strip-unit($base) * 16px;
  }

  @if $count == 1 {
    @return -zf-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

@function -zf-to-rem($value, $base: null) {
  // Check if the value is a number
  @if type-of($value) != 'number' {
    @warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
    @return $value;
  }

  // Transform em into rem if someone hands over 'em's
  @if unit($value) == 'em' {
    $value: strip-unit($value) * 1rem;
  }

  // Calculate rem if units for $value is not rem or em
  @if unit($value) != 'rem' {
    $value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
  }

  // Turn 0rem into 0
  @if $value == 0rem {
    $value: 0;
  }

  @return $value;
}

@function unitless-calc($value, $base: null) {

  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: $base-font-size;
  }

  // First, lets convert our $base to pixels

  // If the base font size is a %, then multiply it by 16px
  @if unit($base) == '%' {
    $base: ($base / 100%) * 16px;
  }

  @if unit($base) == 'rem' {
    $base: strip-unit($base) * 16px;
  }

  @if unit($base) == 'em' {
    $base: strip-unit($base) * 16px;
  }

  // Now let's convert our value to pixels too
  @if unit($value) == '%' {
    $value: ($value / 100%) * $base;
  }

  @if unit($value) == 'rem' {
    $value: strip-unit($value) * $base;
  }

  @if unit($value) == 'em' {
    $value: strip-unit($value) * $base;
  }

  // 'px'
  @if unit($value) == 'px' {
    $value: math.div(strip-unit($value), strip-unit($base));
  }

  // assume that line-heights greater than 10 are meant to be absolute in 'px'
  @if unitless($value) and ($value > 10) {
    $value: $value / strip-unit($base);
  }

  // @return decimal-round($value, 2);
  @return $value;
}

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// See https://codepen.io/kevinweber/pen/dXWoRw
//
// Requires the use of quotes around data URIs.

@function escape-svg($string) {
  @if str-index($string, "data:image/svg+xml") {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, "url(") == 1 {
        $string: url("#{str-replace(str-slice($string, 6, -3), $char, $encoded)}");
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}

@function add($value1, $value2, $return-calc: true) {
  @if $value1 == null {
    @return $value2;
  }

  @if $value2 == null {
    @return $value1;
  }

  @if type-of($value1) == number and type-of($value2) == number and comparable($value1, $value2) {
    @return $value1 + $value2;
  }

  @return if($return-calc == true, calc(#{$value1} + #{$value2}), $value1 + unquote(" + ") + $value2);
}

@function map-get-multiple($map, $values) {
  $result: ();
  @each $key, $value in $map {
    @if (index($values, $key) != null) {
      $result: map-merge($result, ($key: $value));
    }
  }
  @return $result;
}

@function decimal-round ($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @if $digits > 0 {
        @for $i from 1 through $digits {
            $n: $n * 10;
        }
    }
    @if $mode == round {
        @return round($number * $n) / $n;
    } @else if $mode == ceil {
        @return ceil($number * $n) / $n;
    } @else if $mode == floor {
        @return floor($number * $n) / $n;
    } @else {
        @warn '#{ $mode } is undefined keyword.';
        @return $number;
    }
}