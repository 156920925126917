// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
) !default;


// Transitions
$enable-transitions: true !default;
$transition-duration: 0.4s !default;
$transition-timing-function: ease !default;
$transition-duration-slide: $transition-duration !default;
$transition-duration-fade: $transition-duration !default;
$transition-duration-load: $transition-duration !default;
$transition-duration-scroll: $transition-duration !default;
$transition-duration-accordion-slide: $transition-duration !default;
$transition-duration-accordion-fade: $transition-duration !default;
$transition-duration-loading: 0.8s !default;

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

//Spacing
$spacer: 1rem !default;
$spacers: (
  0: 0,
  10: rem-calc(10px),
  20: rem-calc(20px),
  30: rem-calc(30px),
  40: rem-calc(40px),
  50: rem-calc(50px),
) !default;


//Borders
$border-width: 1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;
$border-radius: 0 !default;
$border-color: null !default;


//Utilities
$enable-important-utilities: true !default;
$colors: () !default;
// $gradients: () !default;
$max-widths: () !default;
$min-heights: () !default;


$utilities: (
  "margin": (
    responsive: true,
    property: margin,
    class: m,
    values: map-merge($spacers, (auto: auto))
  ),
  "margin-x": (
    responsive: true,
    property: margin-right margin-left,
    class: mx,
    values: map-merge($spacers, (auto: auto))
  ),
  "margin-y": (
    responsive: true,
    property: margin-top margin-bottom,
    class: my,
    values: map-merge($spacers, (auto: auto))
  ),
  "margin-top": (
    responsive: true,
    property: margin-top,
    class: mt,
    values: map-merge($spacers, (auto: auto))
  ),
  "margin-end": (
    responsive: true,
    property: margin-right,
    class: me,
    values: map-merge($spacers, (auto: auto))
  ),
  "margin-bottom": (
    responsive: true,
    property: margin-bottom,
    class: mb,
    values: map-merge($spacers, (auto: auto))
  ),
  "margin-start": (
    responsive: true,
    property: margin-left,
    class: ms,
    values: map-merge($spacers, (auto: auto))
  ),
  "padding": (
    responsive: true,
    property: padding,
    class: p,
    values: $spacers
  ),
  "padding-x": (
    responsive: true,
    property: padding-right padding-left,
    class: px,
    values: $spacers
  ),
  "padding-y": (
    responsive: true,
    property: padding-top padding-bottom,
    class: py,
    values: $spacers
  ),
  "padding-top": (
    responsive: true,
    property: padding-top,
    class: pt,
    values: $spacers
  ),
  "padding-end": (
    responsive: true,
    property: padding-right,
    class: pe,
    values: $spacers
  ),
  "padding-bottom": (
    responsive: true,
    property: padding-bottom,
    class: pb,
    values: $spacers
  ),
  "padding-start": (
    responsive: true,
    property: padding-left,
    class: ps,
    values: $spacers
  )
);
