@keyframes toggle {
  0% {
    opacity: 0;
    transform: translateY(rem-calc(15px));
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.animate-on-load, .animate-on-ready{
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-duration-load $transition-timing-function, visibility $transition-duration-load $transition-timing-function;
}

.animate-on-load.animate, .animate-on-ready.animate{
  opacity: 1;
  visibility: visible;
}

.animate-on-scroll{
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
  transition: opacity $transition-duration-scroll $transition-timing-function, visibility $transition-duration-scroll $transition-timing-function;
  // &[data-scroll="in"]{
  //   opacity: 1;
  //   visibility: visible;
  // }
  &.animate{
    opacity: 1;
    visibility: visible;    
  }
}

// [data-scroll="in"]{
//   .animate-on-scroll{
//     opacity: 1;
//     visibility: visible;
//   }
// }

// .page-transitioning{
//   .page-container{
//     position: absolute;
//     width: 100%;
//   }
// }

// .page{
//   opacity: 0;
//   visibility: hidden;
//   transition: opacity $transition-duration-fade $transition-timing-function, visibility $transition-duration-fade $transition-timing-function;
// }

// .hide-up, .hide-down{
//   transition: transform $transition-duration-loading $transition-timing-function;
// }

// .hide-up{
//   transform: translateY(-100%);
// }
// .hide-down{
//   transform: translateY(100%);
// }
