.cover-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .cover{
    flex: 1 0 auto;
    display: flex;
    position: relative;
  }
  .cover-content{
    position: relative;
    width: 100%;
  }
  // .cover-slideshow{
  //   width: 100%;
  //   .cover-content{
  //     height: 100%;
  //   }
  //   .slideshow-controls-container{
  //     position: absolute;
  //     right: 0;
  //     bottom: 0;
  //     left: 0;
  //     z-index: 1;
  //   }
  // }
  @include media-breakpoint-down(md) {
    min-height: 100vh;
    .cover-content{
      .row{
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
    }
  }
}