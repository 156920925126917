@if $enable-grid-classes{
  // 100% wide container at all breakpoints
  .container{
    @include make-container();
  }

  // Responsive containers that are 100% wide until a breakpoint
  @each $breakpoint, $container-max-width in $container-max-widths {

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      %responsive-container-#{$breakpoint} {
        width: 100%;
        max-width: $container-max-width;
      }

      // Extend each breakpoint which is smaller or equal to the current breakpoint
      $extend-breakpoint: true;

      @each $name, $width in $breakpoints {
        @if ($extend-breakpoint) {
          .contain{
            margin-right: auto;
            margin-left: auto;
            @extend %responsive-container-#{$breakpoint};
          }

          // Once the current breakpoint is reached, stop extending
          @if ($breakpoint == $name) {
            $extend-breakpoint: false;
          }
        }
      }
    }
  }
}

// Row
//
// Rows contain your columns.

@if $enable-grid-classes {
  .row {
    @include make-row();

    > * {
      @include make-col-ready();
    }
  }
}

.col-half{
  @include make-col(1, 2);
}

.col-lg-half{
  @include media-breakpoint-up(lg) {
    @include make-col(1, 2);
  }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}
