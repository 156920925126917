@mixin hover {
  @media (hover: hover), all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:hover {
      @content;
    }
  }
}

@mixin hover-focus {
  @media (hover: hover), all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:hover,
    &:focus {
      @content;
    }
  }
}

@mixin hover-focus-active {
  @media (hover: hover), all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:hover,
    &:focus,
    &:active {
      @content;
    }
  }
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

@mixin box-shadow($h-offset, $v-offset, $blur, $spread, $color, $inset: false) {
  @if $inset {
    box-shadow: inset $h-offset $v-offset $blur $spread $color;
  } @else {
    box-shadow: $h-offset $v-offset $blur $spread $color;
  }
}

@mixin border-radius($radius: $border-radius) {
  border-radius: $radius;
}

// @mixin border-radius($radius: $border-radius, $fallback-border-radius: false) {
//   @if $enable-rounded {
//     border-radius: valid-radius($radius);
//   }
//   @else if $fallback-border-radius != false {
//     border-radius: $fallback-border-radius;
//   }
// }

// @mixin transition($transition...) {
//   @if $enable-transitions {
//     @if length($transition) == 0 {
//       transition: $transition-base;
//     } @else {
//       transition: $transition;
//     }
//   }

//   @if $enable-prefers-reduced-motion-media-query {
//     @media (prefers-reduced-motion: reduce) {
//       transition: none;
//     }
//   }
// }

@mixin overlay($position: absolute, $z-index: auto) {
  position: $position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index;
}

@mixin aspect-ratio($width, $height, $class: "ratio-content" ) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .#{$class} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin menu-button-color($bar-color: $grey, $bar-hover-color: $link-hover-color ){
  .bar{
    background: $bar-color;
  }
  &.active, &.close{
    .bar{
      background: $bar-color;
    }
  }
  @include hover-focus{
    .bar{
      background: $bar-hover-color;
    }
  }
}

@mixin background($size: cover, $position: center center){
  background-size: $size;
  background-repeat: no-repeat;
  background-position: $position;
}

// @mixin sr-only {
//   position: absolute !important;
//   width: 1px !important;
//   height: 1px !important;
//   padding: 0 !important;
//   margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
//   overflow: hidden !important;
//   clip: rect(0, 0, 0, 0) !important;
//   white-space: nowrap !important;
//   border: 0 !important;
// }

// Use to only display content when it's focused.
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1

@mixin visually-hidden-focusable {
  &:not(:focus) {
    @include visually-hidden();
  }
}

@mixin visually-hidden() {
  // stylelint-disable-next-line
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
}

// Reverse the properties applied by @mixin visually-hidden
// Accepts:
// - $position: {String} Positioning method for element
// Usage:
// .selector {
//   @include visually-shown(relative);
// }
 
@mixin visually-shown($position: static) {
  position: $position !important;
  width: auto;
  height: auto;
  margin: 0;
  overflow: auto;
  clip: auto;
  white-space: normal;
}

@mixin underline($border-width: $border-width, $padding-bottom: 0 ){
  display: inline-block;
  padding-bottom: $padding-bottom;
  border-bottom: $border-width solid;
}

@mixin underline-hover($color: null, $bottom: 0){
  position: relative;
  display: inline-block;
  @if $color {
    color: $color;
  }
  &:after{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: $bottom;
    border-bottom: 1px solid transparent;
    transition: border-color $transition-duration $transition-timing-function;
  }
  @include hover-focus-active {
    opacity: 1;
    &:after{
      border-bottom: 1px solid;
    }
  }
}

@mixin list-unstyled{
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin list-inline(){
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  li{
    flex: 0 0 auto;
  }
}