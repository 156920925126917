@font-face {
  font-family: 'Aime';
  src: url('../fonts/FAMAime-Thin.woff2') format('woff2'), url('../fonts/FAMAime-Thin.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.vltr{
  writing-mode: vertical-lr;
}

.fill{
  transition: fill $transition-duration $transition-timing-function;
}

.stroke{
  transition: stroke $transition-duration $transition-timing-function;
}

.background-container, .foreground{
  position: relative;
}

.background-container{
  overflow: hidden;
}

.background-video{
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.background-image{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: $grey-light;
  @include background();
}

.header-offset{
  padding-top: $header-offset;
  @include media-breakpoint-up(lg) {
    padding-top: $header-offset-lg;
  }
}

.page-container{
  background-color: $body-bg;
  .page{
    overflow: hidden;
    min-height: calc(100vh - $header-offset-lg);
    background-color: $body-bg;
    margin-bottom: calc(100vh - $header-offset-lg);
  }
}

.inter-body-bold{
  @include font-size-map(map-get($responsive-font-sizes, "body-bold"));
}

.horizontal-container{
  .row{
    flex-wrap: nowrap;
    flex-grow: 1;
  }
}

.scroll-container{
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar { 
    display: none;
  }
  .row{
    flex-wrap: nowrap;
    flex-grow: 1;
  }
  // &.hide-scrollbar{
  //   -ms-overflow-style: none;
  //   scrollbar-width: none;
  //   &::-webkit-scrollbar { 
  //     display: none;
  //   }
  // }
}

.landing-container{
  display: none;
}

.overflow-container{
  overflow: hidden;
}

.aspect-holder{
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.masonary{
  display: grid;
  width: 100%;
  grid-column-gap: rem-calc(20px);
  grid-row-gap: rem-calc(20px);
  grid-template-columns: repeat(3,minmax(0,1fr));
  .aspect-holder{
    aspect-ratio: 275/181;
  }
}

.block-1{
  .aspect-holder{
    aspect-ratio: 373/248;
  }
}

.block-3, .block-4, .block-7, .block-8, .block-9 {
  .aspect-holder{
    aspect-ratio: 275/372;
  }
}

.block-1{
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

.block-2{
  grid-row: span 1;
}

.block-3{
  grid-column-start: 3;
  grid-row: span 2 / span 2;
}

.block-4{
  grid-column: 1 / span 1;
  grid-row: 3 / span 2;
}

.block-6{
  grid-column-start: 2;
  grid-row-start: 4;
}

.block-7{
  grid-column-start: 3;
  grid-row: span 2;
}

.block-8, .block-9{
  grid-row-start: 5;
  grid-row: span 2;
}

.hover-container{
  position: relative;
  display: block;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-color: $grey-light;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .overlay{
    opacity: 0;
    transition: opacity $transition-duration-slide $transition-timing-function;
    // background-color: rgba($purple-pink, 0.75);
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .subject, .artist, .client, .conjunction{
      mix-blend-mode: difference;
    }
    .subject, .artist, .client{
      font-family: $font-family-serif;
      text-transform: uppercase;
      font-size: 3.5vw;
      line-height: 100%;
      letter-spacing: -0.02em;
    }
    .conjunction{
      font-size: 1.2vw;
      line-height: 200%;
      letter-spacing: 0.2em;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
    .subject{
      transform: rotate(-7deg);
      transition: transform $transition-duration-slide $transition-timing-function;
    }
    .artist{
      transform: rotate(-7deg) translateY(-45px);
      transition: transform $transition-duration-slide $transition-timing-function;
    }
    .client{
      transform: rotate(-7deg) translateY(45px);
      transition: transform $transition-duration-slide $transition-timing-function;
    }
  }
  @include hover-focus-active {
    .overlay{
      opacity: 0.75;
    }
    .subject, .artist, .client{
      transform: rotate(0) translateY(0);
    }
  }
}

.artist-menu{
  @include list-unstyled();
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  // align-items: center;
  text-align: center;
  a{
    position: relative;
    z-index: 1;
    text-align: center;
    display: inline-block;
    opacity: 1;
    // -webkit-backface-visibility: hidden;
    transition: color $transition-duration-slide $transition-timing-function, opacity $transition-duration-slide $transition-timing-function;
  }
  .background-container{
    background-color: $grey-light;
    @include aspect-ratio(26,17);
    width:100%;
    pointer-events: none;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 30%, 0px) skew(0, -10deg);
    transition: transform $transition-duration-slide $transition-timing-function, opacity $transition-duration-slide $transition-timing-function, visibility $transition-duration-slide $transition-timing-function;
    opacity: 0;
    visibility: hidden;
    max-width: rem-calc(780px);
    // -webkit-backface-visibility: hidden;
  }
  &.focus{
    a{
      opacity: 0.3;
      // transition: opacity $transition-duration-slide $transition-timing-function;
      // mix-blend-mode: difference;
      // transition: color $transition-duration $transition-timing-function;
    }
    a:hover{
      color: $purple;
      opacity: 1;
      // transition: opacity $transition-duration-slide $transition-timing-function;
      // mix-blend-mode: normal;
      // transition: color $transition-duration $transition-timing-function;
    }
    .background-container{
      transform: translate3d(-50%, 0, 0px) skew(0);
      opacity: 1;
      visibility: visible;
    }
  }
}

.page-artist, .page-article{
  .cover-container{
    position: relative;
    min-height: calc(100vh - $header-offset-lg);
    flex-direction: row;
    .cover{
      width: 50vw;
      align-items: center;
      .cover-content{
        color: $white;
        @include link-color($white);
        text-align: center;
      }
    }
    .horizontal-container{
      height: calc(100vh - $header-offset-lg);
      display: flex;
    }
    .horizontal-content{
      position: relative;
      z-index: 2;
      background: $body-bg;
    }
    .first{
      height: 100%;
      width: 50vw;
      flex: 0 0 50vw;
      img, .aspect-holder{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .grid{
      height: 100%;
      img, .aspect-holder{
        height: 100%;
        img{
          width: auto;
        }
      }
    }
  }
}

.page-article{
  .cover-container{
    background-color: #561799;
  }
}

.page-artist{
  .cover-container{
    background-color: #0F1E25;
  }
  .scroll-container{
    .hover-container{
      .aspect-holder{
        aspect-ratio: 275/181;
        &.ratio-275-372{
          aspect-ratio: 275/372;
        }
      }
    }
  }
  .move-this{
    transition: transform $transition-duration $transition-timing-function;
    @include hover-focus-active {
      transform: translate3d(-60px, 0px, 0px);
    }
  }
  @include media-breakpoint-up(lg) {
    .bio-container{
      overflow: hidden;
      background-color: $grey-dark;
      .bio-content{
        width: 100%;
        transform: translateX(24.6%);
        padding-right: 50%;
      }
      .details-content{
        position: absolute;
        top: 0;
        right: -20px;
        bottom: 0;
        left: 40%;
        padding-right: 15%;
        transform: translateX(34.6%);
      }
      .bio-content, .details-content{
        padding-left: 20px;
        transition: transform $transition-duration-slide $transition-timing-function;
        @include hover-focus-active {
          transform: translateX(0);
        }
      }
    }
  }
}

.page-news{
  .news-row{
    display: flex;
    flex-wrap: wrap;
    .news-item{
      position: relative;
      transition: margin $transition-duration-slide $transition-timing-function, width $transition-duration-slide $transition-timing-function;
      .aspect-holder{
        position: relative;
        height: 100%;
        z-index: 2;
        background: $grey-light;
        aspect-ratio: 321/487;
      }
      .slide-container{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 10px;
        right: 10px;
        bottom: 0;
        background: $grey-light;
        overflow: hidden;
      }
      .slide-content{
        margin-left: 41.3%;
        padding: 20px;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
      }
      &.offset{
        margin-left: 13.3333%;
      }
      @include hover-focus-active {
        width: 46.6666%;
      }
    }
    &.focus{
      .news-item{
        &.offset, &.offset-2{
          margin-left: 0 !important;
        }
      }
    }
  }
}

.page-about{
  .instagram-container{
    .row{
      margin-left: -15%;
      margin-right: -15%;      
    }
    a{
      display: block;
      transition: transform $transition-duration $transition-timing-function;
      @include hover-focus-active {
        transform: scale(1.2, 1.2);
      }
    }
  }
}

.page-contact{
  .map-container{
    position: absolute;
    left: 60%;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

