.header-container{
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  .logo{
    // width: rem-calc(215px);
    height: rem-calc(38px);
    @include media-breakpoint-up(lg) {
      // width: rem-calc(215px);
      height: rem-calc(50px);
    }
  }
  border-bottom: 1px solid;
}

.menu-toggle{
  display: block;
  margin-left: auto;
  position: relative;
  .menu-toggle-label{
    @include font-size-map(map-get($responsive-font-sizes, "body-bold"));
    // @include map-to-props(map-get($font-styles, "body-bold-lg"));
    color: $purple;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(-100%, -50%, 0px);
    padding-right: rem-calc(10px);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-duration-fade $transition-timing-function, visibility $transition-duration-fade $transition-timing-function;
  }
  .menu-toggle-icon{
    display: block;
    width: rem-calc(38px);
    height: rem-calc(38px);
    background-color: black;
    border-radius: 100%;
    transition: background-color $transition-duration-fade $transition-timing-function;
  }
  @include hover-focus-active {
    .menu-toggle-label{
      opacity: 1;
      visibility: visible;
    }
    .menu-toggle-icon{
      background-color: $purple;
    }
  }
  @include media-breakpoint-up(lg) {
    .menu-toggle-icon{
      width: rem-calc(51px);
      height: rem-calc(51px);
    }
  }
}