@use "sass:math";

// Container mixins

// @mixin make-container($gutter: $container-padding-x) {
//   width: 100%;
//   padding-right: var(--#{$variable-prefix}gutter-x, #{$gutter});
//   padding-left: var(--#{$variable-prefix}gutter-x, #{$gutter});
//   margin-right: auto;
//   margin-left: auto;
// }

@mixin make-container($gutters: $grid-gutter-widths) {
  width: 100%;
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      padding-right: math.div($gutter, 2);
      padding-left:  math.div($gutter, 2);
    }
  }
}

/// Grid system
//
// Generate semantic grid columns with these mixins.

// @mixin make-row($gutter: $grid-gutter-width) {
//   --#{$variable-prefix}gutter-x: #{$gutter};
//   --#{$variable-prefix}gutter-y: 0;
//   display: flex;
//   flex-wrap: wrap;
//   margin-top: calc(var(--#{$variable-prefix}gutter-y) * -1); // stylelint-disable-line function-disallowed-list
//   margin-right: calc(var(--#{$variable-prefix}gutter-x) / -2); // stylelint-disable-line function-disallowed-list
//   margin-left: calc(var(--#{$variable-prefix}gutter-x) / -2); // stylelint-disable-line function-disallowed-list
// }

@mixin make-row($gutters: $grid-gutter-widths) {
  display: flex;
  flex-wrap: wrap;
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      margin-right: math.div($gutter, -2);
      margin-left:  math.div($gutter, -2);
    }
  }
}

// @mixin make-col-ready($gutter: $grid-gutter-width) {
//   // Add box sizing if only the grid is loaded
//   box-sizing: if(variable-exists(include-column-box-sizing) and $include-column-box-sizing, border-box, null);
//   // Prevent columns from becoming too narrow when at smaller grid tiers by
//   // always setting `width: 100%;`. This works because we set the width
//   // later on to override this initial width.
//   flex-shrink: 0;
//   width: 100%;
//   max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
//   padding-right: calc(var(--#{$variable-prefix}gutter-x) / 2); // stylelint-disable-line function-disallowed-list
//   padding-left: calc(var(--#{$variable-prefix}gutter-x) / 2); // stylelint-disable-line function-disallowed-list
//   margin-top: var(--#{$variable-prefix}gutter-y);
// }

@mixin make-col-ready($gutters: $grid-gutter-widths) {
  // // Add box sizing if only the grid is loaded
  // box-sizing: if(variable-exists(include-column-box-sizing) and $include-column-box-sizing, border-box, null);
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we set the width
  // later on to override this initial width.
  flex-shrink: 0;
  width: 100%;
  max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      padding-right: math.div($gutter, 2);
      padding-left: math.div($gutter, 2);
    }
  }
}

@mixin make-col($size: false, $columns: $grid-columns) {
  @if $size {
    flex: 0 0 auto;
    width: percentage(math.div($size, $columns));
  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: math.div($size, $columns);
  margin-left: if($num == 0, 0, percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// numberof columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
  > * {
    flex: 0 0 auto;
    width: 100% / $count;
  }
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($column-prefix: col, $offset-prefix: offset, $columns: $grid-columns, $breakpoints: $breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .#{$column-prefix}#{$infix} {
        flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      }

      // .row-cols#{$infix}-auto > * {
      //   @include make-col-auto();
      // }

      .#{$column-prefix}#{$infix}-auto {
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .#{$column-prefix}#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }

        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
            .#{$offset-prefix}#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }

      // Gutters
      // Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
      @each $key, $value in $gutter-utilities {
        .g#{$infix}-#{$key},
        .gx#{$infix}-#{$key} {
          margin-right: math.div($value, 2) * -1;
          margin-left: math.div($value, 2) * -1;
          > .col,
          > [class*="col-"] {
            padding-right: math.div($value, 2);
            padding-left: math.div($value, 2);
          }
        }

        .g#{$infix}-#{$key},
        .gy#{$infix}-#{$key} {
          margin-top: $value * -1;
          > .col,
          > [class*="col-"] {
            margin-top: $value;
          }
        }

      }

    }
  }
}
