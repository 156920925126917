@mixin heading-color($heading-color){
  h1, .heading-1 { 
    color: $heading-color;
  }
  h2, .heading-2 {
    color: $heading-color;
  }
  h3, .heading-3 {
    color: $heading-color;
  }
  h4, .heading-4 {
    color: $heading-color;
  }
  h5, .heading-5{
    color: $heading-color;
  }
  h6, .heading-6{
    color: $heading-color;
  }
}

@mixin link-color($link-color, $link-hover-color: $link-hover-color, $link-focus-color: $link-focus-color, $link-active-color: $link-active-color){
  a:not([class*="button-"]), button:not([class*="button-"]) {
    color: $link-color;
    .fill{
      fill: $link-color;
    }
    .stroke{
      stroke: $link-color;
    }
    @include hover {
      color: $link-hover-color;
      .fill{
        fill: $link-hover-color;
      }
      .stroke{
        stroke: $link-hover-color;
      }
    }
    &:focus{
      color: $link-focus-color;
      .fill{
        fill: $link-focus-color;
      }
      .stroke{
        stroke: $link-focus-color;
      }
    }
    &.active{
      color: $link-active-color;
      .fill{
        fill: $link-active-color;
      }
      .stroke{
        stroke: $link-active-color;
      }
    }
  }
}

@mixin fill-color($color){
  .fill{
    fill: $color;
  }
}

@mixin blockquote-color($blockquote-color){
  blockquote, .blockquote {
    color: $blockquote-color;
  }
}

@mixin border-color($border-color){
  .underline{
    border-color: $border-color;
  }
  .form-control {
    border-color: $border-color;
  }
  .button{
    border-color: $border-color;
    @include hover {
      border-color: $border-color;
    }
  }
}

@mixin color($color, $hover-color: $link-hover-color, $focus-color: $link-focus-color, $active-color: $link-active-color){
  color: $color;
  @include fill-color($color);
  @include link-color($color, $hover-color, $focus-color, $active-color);
}

@mixin form-color($input-color, $input-border-color: $input-color, $input-placeholder-color: $input-color, $input-placeholder-opacity: $input-placeholder-opacity){
  .form-control{
    color: $input-color;
    border-color: $input-border-color;
    &::placeholder {
      color: $input-placeholder-color;
      opacity: $input-placeholder-opacity;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    caret-color: $input-color;
    color: $input-color;
    -webkit-text-fill-color: $input-color;
    -webkit-text-stroke-color: $input-color;
    -webkit-tap-highlight-color: $input-color;
  }
}

@mixin menu-button-color($bar-color: $body-color, $bar-hover-color: $link-hover-color){
  .bar{
    background: $bar-color;
  }
  &.active, &.close{
    .bar{
      background: $bar-color;
    }
  }
  @include hover-focus{
    .bar{
      background: $bar-hover-color;
    }
  }
}
