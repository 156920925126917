// .color-links-grey{
//   @include link-color($grey);
// }

body {
  background-color: $body-bg; 
  color: $body-color;
}

::selection{
  background-color: $black;
  color: $white;
}

a, button {
  color: $link-color;
  .fill{
    fill: $link-color;
  }
  .stroke{
    stroke: $link-color;
  }
  @include hover {
    color: $link-hover-color;
    .fill{
      fill: $link-hover-color;
    }
    .stroke{
      stroke: $link-hover-color;
    }
  }
  &:focus{
    color: $link-focus-color;
    .fill{
      fill: $link-focus-color;
    }
    .stroke{
      stroke: $link-focus-color;
    }
  }
  &.active{
    color: $link-active-color;
    .fill{
      fill: $link-active-color;
    }
    .stroke{
      stroke: $link-active-color;
    }
  }
}

.fill{
  fill: $body-color;
}