// @mixin font-size($font-size, $line-height) {
//   font-size: rem-calc($font-size);
//   line-height: unitless-calc($line-height, $font-size);
// }

@mixin font-size-map($font-size) {
  @if $font-size != null {
    @if type-of($font-size) == "map" and map-has-key($font-size, font-size){
      @include map-to-props($font-size);
    } @else {
      @each $breakpoint, $font-size in $font-size {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
          @include map-to-props($font-size);
        }
      }
    }
  }
}

@mixin heading-1{
  @include font-size-map($heading-1-font-size);
  font-weight: $heading-1-weight;
}

@mixin heading-2{
  @include font-size-map($heading-2-font-size);
  font-weight: $heading-2-weight;
}

@mixin heading-3{
  @include font-size-map($heading-3-font-size);
  font-weight: $heading-3-weight;
}

@mixin heading-4{
  @include font-size-map($heading-4-font-size);
  font-weight: $heading-4-weight;
}

@mixin heading-5{
  @include font-size-map($heading-5-font-size);
  font-weight: $heading-5-weight;
}

@mixin heading-6{
  @include font-size-map($heading-6-font-size);
  font-weight: $heading-6-weight;
}

@mixin lead{
  font-family: $lead-font-family;
  @include font-size-map($lead-font-size);
  font-weight: $lead-font-weight;
  color: $lead-color;
}

@mixin blockquote{
  font-family: $blockquote-font-family;
  @include font-size-map($blockquote-font-size);
  font-weight: $blockquote-font-weight;
  color: $blockquote-color;
}

@mixin text-small(){
  @include font-size-map($small-font-size);
}

@mixin text-bold{
  font-weight: $font-weight-bold;
}

@mixin text-uppercase($letter-spacing: $uppercase-letter-spacing, $font-weight: $uppercase-font-weight){
  text-transform: uppercase;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  // https://stackoverflow.com/questions/2760784/how-to-calculate-css-letter-spacing-v-s-tracking-in-typography
}
