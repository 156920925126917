//Grid
$enable-grid-classes: true !default;
$grid-columns: 12 !default;
$grid-gutter-widths: (
  xs: rem-calc(30px)
) !default;
$gutter-utilities: $spacers !default;


@include _assert-ascending($breakpoints, "$breakpoints");
@include _assert-starts-at-zero($breakpoints, "$breakpoints");

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px
// ) !default;

$container-max-widths: (
  xl: map-get($breakpoints, "xl")
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");