.menu-container{
  @include overlay(fixed, 4);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 0s $transition-timing-function $transition-duration-fade, visibility 0s $transition-timing-function $transition-duration-fade;
  -webkit-backface-visibility: hidden;
  .overlay{
    @include overlay(absolute);
    background-color: black;
    clip-path: circle(0% at calc(100% - 45px) 45px);
    transition: clip-path $transition-duration-slide $transition-timing-function, background-color $transition-duration-slide $transition-timing-function;
  }
  .close{
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }
  .menu{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: $grey-light;
    @include link-color($grey-light);
    @include fill-color($grey-light);
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-duration-slide $transition-timing-function, visibility $transition-duration-slide $transition-timing-function;
    .main-menu{
      @include list-unstyled();
      @include font-size-map(map-get($responsive-font-sizes, "body-bold"));
      // @include map-to-props(map-get($font-styles, "body-bold-lg"));
      margin: 0 auto;
    }
  }
  @include media-breakpoint-up(lg) {
    .menu{
      .main-menu{
        @include list-inline();
      }
    }
  }
}