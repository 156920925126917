.viewport{
  &.scrolled{
    .header-container{
      opacity: 1;
      visibility: visible;
    }
  }
  &.loaded{
    .loading-container{
      opacity: 0;
      visibility: hidden;
    }
  }
  &.animate-footer{
    .footer-container{
      #footer-wordmark{
        width: 200%;
      }
    }
  }
  &.menu-open{
    .menu-container{
      opacity: 1;
      visibility: visible;
      transition: opacity 0s $transition-timing-function 0s, visibility 0s $transition-timing-function 0s;
      .overlay{
        background-color: $purple;
        clip-path: circle(150% at calc(100% - 45px) 45px);
        transition: clip-path $transition-duration-slide $transition-timing-function, background-color 0s $transition-timing-function;
      }
      .menu{
        opacity: 1;
        visibility: visible;
        transition: opacity $transition-duration-fade $transition-timing-function, visibility $transition-duration-fade $transition-timing-function;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    // &.menu-open{
    //   .menu-container{
    //     opacity: 1;
    //     visibility: visible;
    //     transition: opacity 0s $transition-timing-function 0s, visibility 0s $transition-timing-function 0s;
    //     .overlay{
    //       background-color: $purple;
    //       clip-path: circle(150% at calc(100% - 45px) 45px);
    //       transition: clip-path $transition-duration-slide $transition-timing-function, background-color 0s $transition-timing-function;
    //     }
    //     .menu{
    //       opacity: 1;
    //       visibility: visible;
    //       transition: opacity $transition-duration-fade $transition-timing-function, visibility $transition-duration-fade $transition-timing-function;
    //     }
    //   }
    // }
  }
}

.intro{
  overflow: hidden;
  .landing-container{
    display: block;
    .logo{
      transform-origin: 0 0;
      position: fixed;
      left: rem-calc(20px);
      top: rem-calc(20px);
      z-index: 1;
      width: rem-calc(792px);
      // height: rem-calc(50px);
      @include media-breakpoint-up(lg) {
        width: rem-calc(792px);
        // height: rem-calc(50px);
      }
    }
    .aspect-holder{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -2;
      transform: translate3d(100%, 0px, 0px);
      // &:nth-child(3){
      //   left: rem-calc(20px);
      // }
      // &:nth-child(4){
      //   left: rem-calc(40px);
      // }
      // &:nth-child(5){
      //   left: rem-calc(60px);
      // }
    }
  }
  .viewport{
    // transform: scale(0.5);
    // transform-origin: 50% 0;
    transform: translate3d(100%, 0px, 0px);
    overflow: hidden;
    // transform-origin: 0 0;
    .header-container{
      .logo{
        opacity: 0;
      }
    }
    .footer-container{
      display: none;
    }
  }
}