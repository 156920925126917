.footer-container{
  position: fixed;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $purple;
  height: calc(100vh - $header-offset-lg);
  color: $grey-light;
  @include link-color($grey-light);
  @include fill-color($grey-light);
}