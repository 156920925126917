button{
  &:disabled,
  &[readonly] {
    cursor: default;
    color: $body-color;
    opacity: $button-opacity-disabled;
  }
}

@each $button, $arguments in $buttons {
  // @debug $arguments;
  .#{$button} {
    @include button-variant($arguments...);
  }
}

// .button{
//   @include button();
// }

.button-block{
  display: block;
  width: 100%;
}