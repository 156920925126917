@use "sass:math";

//Buttons
$button-font-family: inherit !default;
$button-font-size: $body-font-size !default;
// $button-line-height: 1 !default;
$button-font-weight: $font-weight-regular !default;

$button-color: $body-color !default;
$button-bg: $body-bg !default;

$button-bordered: true !default;
$button-border-color: $border-color !default;
$button-border-width: $border-width !default;
$button-border-radius: $border-radius !default;

$button-color-hover: lighten($button-color, 50%) !default;
$button-bg-hover: $grey-mid !default;
$button-border-color-hover: $button-bg-hover !default;

$button-color-active: lighten($button-color, 50%) !default;
$button-bg-active: $grey-mid !default;
$button-border-color-active: $button-bg-hover !default;

$button-color-disabled: $button-color !default;
$button-bg-disabled: $button-bg !default;
$button-opacity-disabled: 0.5 !default;

$button-padding: (
  "top": $spacer/2,
  "right": $spacer,
  "bottom": $spacer/2,
  "left": $spacer,
) !default;

// //Button small
// $button-font-size-sm: map-get($responsive-font-sizes, button-sm);
// $button-padding-sm: (
//   "top": math.div($spacer, 4),
//   "right": math.div($spacer, 2),
//   "bottom": math.div($spacer, 4),
//   "left": math.div($spacer, 2),
// ) !default;

// //Button Large
// $button-padding-lg: (
//   "top": $spacer,
//   "right": $spacer*.1.2,
//   "bottom": $spacer,
//   "left": $spacer*.1.2,
// ) !default;

$buttons: (
  "button": (

    button-font-family: $button-font-family,
    button-font-size: $button-font-size,
    button-font-weight: $button-font-weight,

    button-color: $button-color,
    button-bg: $button-bg,

    button-bordered: $button-bordered,
    button-border-color: $button-border-color,
    button-border-width: $button-border-width,
    button-border-radius: $button-border-radius,

    button-color-hover: $button-color-hover,
    button-bg-hover: $button-bg-hover,
    button-border-color-hover: $button-border-color-hover,

    button-color-active: $button-color-active,
    button-bg-active: $button-bg-active,
    button-border-color-active: $button-border-color-active,

    button-color-disabled: $button-color-disabled,
    button-bg-disabled: $button-bg-disabled,
    button-opacity-disabled: $button-opacity-disabled,

    button-padding: $button-padding
  )
) !default;