// Bootstrap functions
// Utility mixins and functions for evaluating source code across our variables, maps, and mixins.

// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null or unit($num) == "%" or unit($prev-num) == "%" {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// Starts at zero
// Used to ensure the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map, $map-name: "$breakpoints") {
  @if length($map) > 0 {
    $values: map-values($map);
    $first-value: nth($values, 1);
    @if $first-value != 0 {
      @warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
    }
  }
}

@mixin output-variable($value, $properties) {
  @if $value != null {
    @if type-of($value) == "map" and map-has-key($value, "xs"){
      @each $breakpoint, $value in $value {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
          @if type-of($properties) == "list" and $value {
            @each $property in $properties {
              #{unquote($property)}: $value;
            }
          }
        }
      }
    } @else {
      @if type-of($properties) == "list" and $value {
        @each $property in $properties {
          #{unquote($property)}: $value;
        }
      }
      @else {
        #{unquote($properties)}: $value;
      }
    }
  }
  @else {
    @warn "There must be a value";
  }
}

@mixin map-to-props($map){
  @if type-of($map) == map {
    @each $prop, $value in $map {
      @if type-of($value) != map {
          @debug $prop;
          @debug $value;
        @if inspect($prop) == 'description' {
          /* #{inspect($value)} */
        }
        @else {
          #{inspect($prop)}: #{inspect($value)};
        }
      }
      @else {
        @include map-to-props($value);
      }
    }
  }
  @else {
    @warn "Value must be a map";
  }
}