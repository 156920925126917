//Colours
$black: #000000 !default;
$white: #FFFFFF !default;
$grey: #CCCCCC !default;
$grey-light: #EFEFEF !default;
$grey-mid: #666666 !default;
$grey-dark: #333333 !default;
$red: #980000 !default;

$error-color: $red !default;

$body-bg: $white !default;
$body-color: $grey-dark !default;

$link-color: $body-color !default;
$link-hover-color: lighten($link-color, 50%) !default;
$link-focus-color: $link-hover-color !default;
$link-active-color: $link-hover-color !default;